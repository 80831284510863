import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class ServiceEdit {

  private service: Models.Service;
  private net: Models.Net;
  private originalObject;
  private isDeleting = false;

  constructor(
    private i18n: I18N,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private serviceService: ServiceService,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private netService: NetService,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt
  ) { }

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);
  }

  private getService(id) {
    this.serviceService
      .get(id)
      .then((res) => {
        this.service = res;
        this.originalObject = JSON.parse(JSON.stringify(res));

        ValidationRules
          .ensure('NetId').required().withMessage(this.i18n.tr('general.requiredField'))
          .ensure('NetStandardId').required().withMessage(this.i18n.tr('general.requiredField'))
          .on(this.service);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.service)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateService();
        }
      });
  }

  private updateService() {
    this.serviceService
      .put(this.service, this.service.Id, null, ['PlannedDeliveryDate'])
      .then((res) => {
        this.originalObject = null;
        this.toastService.showSuccess('service.updated');
        this.router.navigateToRoute('service-detail', { Id: res.Id, NetId: res.NetId });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    // fix dates
    this.service = this.utility.areEqualDateFix(this.service, [{ key: 'ReceivedDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ReceivedDate' }]);

    if (!this.utility.areEqual(this.service, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue' }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  deleteService() {
    this.isDeleting = true;

    this.serviceService.delete(this.service.Id)
      .then(res => {
        this.toastService.showSuccess('service.deleted');
        this.router.navigateToRoute('service-list');
        this.isDeleting = false;
      })
      .catch(err => {
        this.errorService.handleError(err);
        this.isDeleting = false;
      })
  }
}
