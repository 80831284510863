import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM, bindable, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NetService } from 'services/net-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { RopeService } from 'services/rope-service';
import { ServiceConsumptionExtraRopeService } from 'services/service-consumption-extra-rope-service';
import { ServiceConsumptionNettingService } from 'services/service-consumption-netting-service';
import { ServiceConsumptionRopeService } from 'services/service-consumption-rope-service';
import { ServiceConsumptionThreadService } from 'services/service-consumption-thread-service';
import { ServiceService } from 'services/service-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("service-detail-spaghetti");

@autoinject
export class ServiceDetailSpaghetti {

  private net: Models.Net;
  private service: Models.Service;
  private serviceConsumptionThreads: Array<Models.ServiceConsumptionThread>;
  private serviceConsumptionNettings: Array<Models.ServiceConsumptionNetting>;
  private serviceConsumptionRopes: Array<Models.ServiceConsumptionRope>;
  private serviceConsumptionExtraRopes: Array<Models.ServiceConsumptionExtraRope>;
  private serviceid: number;
  private minimumBreakingStrength: number;
  private locked;
  private nextTabIndex: number = null;
  private threadsDirty;
  private nettingsDirty;
  private ropesDirty;
  private loopsDirty;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private meshSizeService: MeshSizeService,
    private netService: NetService,
    private nylonTypeService: NylonTypeService,
    private ropeService: RopeService,
    private router: Router,
    private serviceConsumptionExtraRopeService: ServiceConsumptionExtraRopeService,
    private serviceConsumptionNettingService: ServiceConsumptionNettingService,
    private serviceConsumptionRopeService: ServiceConsumptionRopeService,
    private serviceConsumptionThreadService: ServiceConsumptionThreadService,
    private serviceService: ServiceService,
    private threadTypeService: ThreadTypeService,
    private toastService: ToastService,
    private utility: Utility
  ) { }

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  // Materialforbruk reparasjon              ===   MaterialConsumptionThread
  // _______________ notlin utvidelse        ===   ___________________Netting

  // _______________ løkker/ringer/utstyr    ===   ___________________ExtraRope

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);
    this.serviceid = params.Id;

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

  }

  public canDeactivate() {

    if (this.threadsDirty || this.nettingsDirty || this.ropesDirty || this.loopsDirty) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'continue') {
            this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
            return true;
          }
        }
      });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private getService(id) {
    this.serviceService
    .get(id)
    .then((res) => {
      logger.debug('Service',res);

      this.service = res;
      this.locked = res.Locked ? res.Locked : false;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  // Get net and netdimension and calculate min breaking strength
  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension')
      .then((res) => {
        if (res.NetDimension && res.NetDimension.DimensionClassId) {
          this.ropeService.calculateMinBreakingStrength(res.NetDimension.DimensionClassId)
            .then((result) => {
              this.minimumBreakingStrength = result.MinBreakingStrength;
              this.net = res;
            })
            .catch((err) => this.errorService.handleError(err));

        } else {
          this.net = res;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private saveChanges() {
    this.serviceService
    .put(this.service, this.serviceid)
    .then((res) => {
      this.toastService.showSuccess('service.spaghettiSaved');
      this.eventAggregator.publish('serviceUpdated');
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private updateStatusSpaghettiComplete(){
    this.service.SpaghettiDate = new Date();

    this.serviceService
    .put(this.service, this.serviceid)
    .then((res) => {
      this.toastService.showSuccess('service.spaghettiSaved');
      this.eventAggregator.publish('serviceUpdated');
    })
    .catch((err) => this.errorService.handleError(err));
  }
}
