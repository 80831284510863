import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceMeasurementService } from 'services/service-measurement-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import * as moment from 'moment';

@autoinject
export class ServiceDetailTest {
  private net: Models.Net;
  private netdimension: Models.NetDimension;
  private specialProductDimension: Models.SpecialProductDimension;
  private measurements: Array<any>;
  private serviceid: number;
  private calculations;
  private service: Models.Service;
  private originals: any = {};
  private originalObject;
  private locked;
  private nextTabIndex: number = null;
  private showWarningNoMinBreakingStrength = false;
  private serviceIsNS94152021 = false;
  private testListResetSubscription: Subscription;
  private editTableData = false;
  private message: string;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private serviceMeasurementService: ServiceMeasurementService,
    private netDimensionService: NetDimensionService,
    private serviceService: ServiceService,
    private specialProductDimensionService: SpecialProductDimensionService,
    private i18n: I18N
  ) {}

  private activate(params: { NetId: number; Id: number }) {
    this.getNet(params.NetId, params.Id);
    this.getCalculations(params.Id);
    this.getService(params.Id);
    this.serviceid = params.Id;

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }

    this.testListResetSubscription = this.eventAggregator.subscribe('serviceTestListReset', (value) => {
      this.getMeasurements(this.serviceid);
      this.getCalculations(this.serviceid);
      return;
    });
  }

  private detached() {
    if (this.testListResetSubscription) {
      this.testListResetSubscription.dispose();
    }
  }

  protected lowestMeasurements = {
    MeasurementBottom: 0,
    MeasurementJumpNet: 0,
    MeasurementMiddleSide: 0,
    MeasurementWaterLine: 0,
  };

  protected getLowestFromMeasurements(key: keyof typeof this.lowestMeasurements) {
    return this.measurements
      .filter((m) => m.ServiceMeasurementTypeId == 5)
      .reduce((acc: number[], current) => {
        acc.push(current?.[key] || 0);
        return acc;
      }, [])
      .sort((a, b) => a - b)[0];
  }

  private async getMeasurements(id: number) {
    try {
      const measurements = await this.serviceMeasurementService.getMeasurements(id);
      this.originals.measurements = JSON.parse(JSON.stringify(measurements));
      this.measurements = measurements;

      this.lowestMeasurements.MeasurementBottom = this.getLowestFromMeasurements('MeasurementBottom');
      this.lowestMeasurements.MeasurementJumpNet = this.getLowestFromMeasurements('MeasurementJumpNet');
      this.lowestMeasurements.MeasurementMiddleSide = this.getLowestFromMeasurements('MeasurementMiddleSide');
      this.lowestMeasurements.MeasurementWaterLine = this.getLowestFromMeasurements('MeasurementWaterLine');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getNet(id: number, serviceId: number) {
    try {
      const net = await this.netService.getNetInfo(id);
      this.net = net;
      this.getDimensions(net);
      this.getMeasurements(serviceId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getService(id: number) {
    try {
      const service = await this.serviceService.get(id + '?$expand=NetStandard');
      this.service = service;
      this.updateOriginalObject();
      this.locked = service.Locked ? service.Locked : false;
      this.serviceIsNS94152021 = service.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getDimensions(net: Models.Net) {
    try {
      if (net.NetDimensionId) {
        const dimensions = await this.netDimensionService.get(net.NetDimensionId + '?$expand=DimensionClass');
        this.netdimension = dimensions;
        this.setTestMessage(this.netdimension.IsCombiNet);
      } else if (net.SpecialProductDimensionId) {
        const dimensions = await this.specialProductDimensionService.get(net.SpecialProductDimensionId);
        this.specialProductDimension = dimensions;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getCalculations(id: number) {
    try {
      const calculatedMeasurements = await this.serviceMeasurementService.getCalculatedMeasurements(id);

      this.calculations = [
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 7),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 1),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 3),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 6),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 2),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 4),
        calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 0),
      ];

      const minBreakingStrengthCalc = calculatedMeasurements.find((x) => x.ServiceMeasurementTypeId === 1);

      if (
        !minBreakingStrengthCalc ||
        minBreakingStrengthCalc.MeasurementBottom === 0 ||
        minBreakingStrengthCalc.MeasurementMiddleSide === 0 ||
        minBreakingStrengthCalc.MeasurementWaterLine === 0 ||
        minBreakingStrengthCalc.MeasurementJumpNet === 0
      ) {
        this.showWarningNoMinBreakingStrength = true;
      } else {
        this.showWarningNoMinBreakingStrength = false;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async saveService() {
    try {
      await this.serviceService.put(this.service, this.serviceid);
      this.toastService.showSuccess('service.testConditionsSaved');
      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  // Not in use in this tab really, but may be in use in the future
  public canDeactivate() {
    this.originalObject.TestedDate = moment.default(this.originalObject.TestedDate).format('YYYY-MM-DD');
    this.service.TestedDate = moment.default(this.service.TestedDate).format('YYYY-MM-DD') as any;

    if (
      this.originalObject &&
      (!this.utility.areEqual(this.service, this.originalObject) ||
        !this.utility.areEqual(this.measurements, this.originals.measurements) ||
        this.editTableData == true)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // Save the service and let that function handle the rest of the logic
              this.saveService();
              return false;
            } else {
              this.cancelEditTableData();
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private editLabel(measurement) {
    let label = this.i18n.tr('general.edit') + ' ';
    if (measurement.MeasurementPlacement === 1) {
      label = label + this.i18n.tr('service.measurementJumpNet');
    }
    if (measurement.MeasurementPlacement === 2) {
      label = label + this.i18n.tr('service.measurementWaterLine');
    }
    if (measurement.MeasurementPlacement === 3) {
      label = label + this.i18n.tr('service.measurementMiddleSide');
    }
    if (measurement.MeasurementPlacement === 4) {
      label = label + this.i18n.tr('service.measurementBottom');
    }
    return label;
  }

  private updateOriginalObject() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

  updateStatusTestComplete() {
    this.serviceService
      .updateServiceStatus(3, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.serviceStatusChanged');
        this.eventAggregator.publish('serviceUpdated');
        if (!this.service.TestedDate) {
          this.service.TestedDate = res.TestedDate;
        }
        this.saveService();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private openSigningTab() {
    if (this.isDialog) {
      this.eventAggregator.publish('changeTabIndexByName', 'signing');
    } else {
      this.router.navigate('service-detail-signing');
    }
  }

  private setTestMessage(isCombiNet: boolean): void {
    this.message = isCombiNet ? 'service.isCombiNetTest' : 'service.isNotCombiNetTest';
  }

  private async updateTableData() {
    try {
      for (const item of this.measurements) {
        // Check if changes has been made
        const originalItem = this.originals.measurements.find((x) => x.Id === item.Id);
        if (!this.utility.areEqual(originalItem, item)) {
          delete item.Service;

          if (!item.Id) {
            await this.serviceMeasurementService.post(item);
          } else {
            await this.serviceMeasurementService.put(item, item.Id);
          }
        }
      }

      this.getMeasurements(this.service.Id);
      this.getCalculations(this.service.Id);
      this.editTableData = false;

      this.toastService.showSuccess('service.testConditionsSaved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private cancelEditTableData() {
    this.measurements = JSON.parse(JSON.stringify(this.originals.measurements));
    this.editTableData = false;
  }
}
